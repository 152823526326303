<template>
    <div class="signin">
        <h2>ログイン</h2>
        <v-btn class="text-capitalize" @click="signIn">Googleアカウントでログイン</v-btn>
        <br/>
        <!-- <v-text class="text-capitalize" >{{logMessage}}</v-text> -->
    </div>
</template>

<script>
  import axios from 'axios'
  import firebase from 'firebase'
  import Endpoints from '../consts/api_endpoint_map.js'

  export default {
    name: "Signin",
    data () {
      return {
        username: '',
        password: '',
        logMessage: 'log',
      }
    },
    methods: {
      async signIn() {
        // await firebase.auth().signInWithEmailAndPassword(this.username, this.password)
        var provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
        var user
        await firebase.auth().signInWithPopup(provider)
          .then(() => {
            user = firebase.auth().currentUser
            this.logMessage += `currentUser : ${user.email}\n`
          })
          .catch(error => {
            console.log(error)
          })
        var userId
        await axios.get(Endpoints.getUserEndpoint + `?email=${user.email}`)
          .then(res => {
            this.logMessage += `cache user\n`
            console.log("cache user")
            this.$store.dispatch('setUserName', {userName: res.data.userName})
            userId = res.data.userId
            this.$store.dispatch('setUserId', {userId: userId})
          })
          .catch(err => {
            this.logMessage += `err while cache user endpoint : ${Endpoints.getUserEndpoint}`
            if(err.response){
              this.logMessage += `err while cache user. response : ${err.response.data}`
              this.logMessage += `,${err.response.status}`
              this.logMessage += `,${err.response.statusText}`
              this.logMessage += `,${err.response.headers}`
            } else if(err.request){
              this.logMessage += `err cache user no response. request: ${err.request}`
            } else {
              this.logMessage += `err cache user : ${err.message}`              
            }
            console.log(err)
            // log-out immediately
            firebase.auth().signOut()
              .then(() => this.$router.push('sign-in'))
          })
        await axios.get(Endpoints.getAuthListEndpoint + `?userId=${userId}`)
          .then(res => {
            this.logMessage += `cache roleTypes`
            console.log("cache roleTypes")
            this.$store.dispatch('setRoleTypes', {roleTypes: res.data})
          })
          .catch(err => {
            this.logMessage += `err cache roleTypes : ${err}`
            console.log(err)
            firebase.auth().signOut()
              .then(() => this.$router.push('sign-in'))
          })
        await axios.get(Endpoints.getMenuListEndpoint + `?userId=${userId}`)
          .then(res => {
            if(res.data){
              this.logMessage += `cache menus\n`
              console.log("cache menu")
              this.$emit("update-menus", res.data)
            }            
          })
          .catch(err => {
            this.logMessage += `err cache menus : ${err}\n`
            console.log(err)
            firebase.auth().signOut()
              .then(() => this.$router.push('sign-in'))
          })
        this.$router.push('/sales/register')
    },
    }
  }
</script>
